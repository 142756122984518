.ReactModal__Body--open {
    overflow: hidden;
}

.modal-content {
    position: absolute;
    border: 1px solid rgb(204, 204, 204);
    background: rgb(255, 255, 255);
    overflow: auto;
    outline: none;
    padding: 15px;
    top: 50%;
    transform: translateY(-50%);
    margin: 10px;
    border-radius: 8px;
    z-index: 10;
    max-height: 0;
    transition: max-height 1s ease-in-out;
    box-sizing: border-box;
    width: calc(100% - 20px);;
    overflow: visible;
}

.modal-content.slide-in {
    max-height: 500px;
    transition: max-height 1s ease-in-out;
}

.modal-overlay {
    position: fixed;
    top: 0px;
    left: 0px;
    right: 0px;
    bottom: 0px;
    background-color: rgba(0, 0, 0, 0.3);
    z-index: 21;
}
