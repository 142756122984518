@import '../../styles/settings';

.btn {
    border-style: none;
    -webkit-appearance: unset;
    font-weight: bold;
    -webkit-text-size-adjust: 100%;
    font-family: inherit;
    cursor: pointer;
    font-size: 14px;
    line-height: 16px;
    text-align: left;
    padding: 16px 18px;
    min-width: 125px;
    width: 100%;
}

.primary {
    background: linear-gradient(333.66deg, #ED5E1E 59.05%, #FF9A5A 102%);
    color: #ffffff;
    box-shadow: 0 2px 4px 0 rgba(73, 73, 73, 0.29);
    border-radius: 4px;
    text-align: center;
    font-weight: bold;
    &.btn-disabled {
        background-color: #bfbfbf;;
    }
}

.secondary {
    color: $pastel-orange;
    text-align: center;
    border-radius: 4px;
    color: #FF9A5A;
    border: 1px solid #FF9A5A;
    background: #FFFFFF;
    &.btn-disabled {
        color: #bfbfbf;
    }
}

.button-text {
    display: inline-block;
    vertical-align: middle;
    width: auto;

    &.has-icon {
        width: calc(100% - 58px);
    }
}
