.goal-container {
    height: inherit;
    display: flex;
    flex-direction: column;
    flex: 1 0 0;

    .goal {
        padding-bottom: 10px;

        &.active {
            z-index: 20;

            img {
                box-shadow: 5px 10px 8px #888888;
            }
        }

        div {
            position: relative;
        }

        .tag {
            width: 100px;
            position: absolute;
            bottom: 16px;
            right: -10px;
        }
    }
}
