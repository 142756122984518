.nav {
    .tooltip {
        right: 10px;

        &.bottom::before {
            left: 86%;
        }
    }

    .download {
        position: absolute;
        right: 15px;
        top: 8px;
        bottom: 4px;
        left: 85%;
    }
}