.next {
    bottom: 5%;
    right: 10%;
    position: fixed;
    z-index: 21;

    span {
        color: #ffffff;
        font-weight: 600;
    }
}