.tooltip {
  width: 50%;
  background-color: #015288;
  color: #fff;
  border-radius: 6px;
  padding: 12px;
  position: absolute !important;
  z-index: 20;
  font-size: 14px;
  margin-top: 5px;

  &.top::after, &.bottom::before {
    content: " ";
    position: absolute;
    left: 50%;
    margin-left: -8px;
    border-width: 8px;
    border-style: solid;
  }

  &.top {
    bottom: 100%;
    transform: translateY(-10px);

    &::after {
        top: 100%;
        border-color: #015288 transparent transparent transparent;
    }
  }

  &.bottom::before {
    bottom: 100%;
    border-color: transparent transparent #015288 transparent;
  }
}