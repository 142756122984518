@import url(https://fonts.googleapis.com/css2?family=Poppins:wght@400;600&display=swap);
body{margin:0;font-family:-apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;-webkit-font-smoothing:antialiased;-moz-osx-font-smoothing:grayscale}code{font-family:source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace}body{font-family:'Poppins', sans-serif}.page-1{top:0;left:0;position:fixed;width:100%}.page-1.slide-out{-webkit-animation:slide-out 0.5s forwards;animation:slide-out 0.5s forwards}@-webkit-keyframes slide-out{from{left:0}to{left:-100%}}@keyframes slide-out{from{left:0}to{left:-100%}}.page-2{top:0;left:100%;position:fixed;width:100%}.page-2.slide-in{-webkit-animation:slide-in 0.5s forwards;animation:slide-in 0.5s forwards}@-webkit-keyframes slide-in{from{left:100%}to{left:0}}@keyframes slide-in{from{left:100%}to{left:0}}.page-1{min-height:100vh;display:flex;flex-direction:column;z-index:1;overflow:scroll;position:relative}.page-1 .content-box{flex:1 1;padding:15px;display:flex;flex-direction:column}.page-2{height:100vh;display:flex;flex-direction:column;z-index:1}.page-2 .content-box{flex:1 1;padding:15px;display:flex;flex-direction:column;overflow:scroll}img{width:100%}.svg{width:100%}.active{z-index:20}.p-relative{position:relative}.d-none{display:none}

.home-container{display:flex;flex-direction:column;height:100vh}.home-container .svg{flex:1 0}.home-container .content-box{padding:20px 20px 30px 20px;display:flex;flex-direction:column;flex:1 0}.home-container .content-box .content{flex:1 1}.home-container .content-box .content .title{font-size:16px;margin-top:0;margin-bottom:8px}.home-container .content-box .content .app-name{margin-top:0;margin-bottom:8px;font-size:24px;color:#FF9A5A;font-weight:bold}.home-container .content-box .content .text{margin-top:0;font-size:12px;color:#969696;line-height:20px}.home-container .content-box Button{align-self:flex-end}

.msg-info{color:#3C71E1}.msg-success{color:#00A84F}.msg-error{color:#eb4a57}.msg-warning{color:#ED7100}.btn{border-style:none;-webkit-appearance:unset;font-weight:bold;-webkit-text-size-adjust:100%;font-family:inherit;cursor:pointer;font-size:14px;line-height:16px;text-align:left;padding:16px 18px;min-width:125px;width:100%}.primary{background:linear-gradient(333.66deg, #ED5E1E 59.05%, #FF9A5A 102%);color:#ffffff;box-shadow:0 2px 4px 0 rgba(73,73,73,0.29);border-radius:4px;text-align:center;font-weight:bold}.primary.btn-disabled{background-color:#bfbfbf}.secondary{color:#FF6200;text-align:center;border-radius:4px;color:#FF9A5A;border:1px solid #FF9A5A;background:#FFFFFF}.secondary.btn-disabled{color:#bfbfbf}.button-text{display:inline-block;vertical-align:middle;width:auto}.button-text.has-icon{width:calc(100% - 58px)}

.home-container{display:flex;flex-direction:column;height:100vh}.home-container .svg{flex:1 0}.home-container .content-box{padding:20px 20px 30px 20px;display:flex;flex-direction:column;flex:1 0}.home-container .content-box .content{flex:1 1}.home-container .content-box .content .title{font-size:16px;margin-top:0;margin-bottom:8px}.home-container .content-box .content .app-name{margin-top:0;margin-bottom:8px;font-size:24px;color:#FF9A5A;font-weight:bold}.home-container .content-box .content .text{margin-top:0;font-size:12px;color:#969696;line-height:20px}.home-container .content-box Button{align-self:flex-end}


.tooltip{width:50%;background-color:#015288;color:#fff;border-radius:6px;padding:12px;position:absolute !important;z-index:20;font-size:14px;margin-top:5px}.tooltip.top::after,.tooltip.bottom::before{content:" ";position:absolute;left:50%;margin-left:-8px;border-width:8px;border-style:solid}.tooltip.top{bottom:100%;transform:translateY(-10px)}.tooltip.top::after{top:100%;border-color:#015288 transparent transparent transparent}.tooltip.bottom::before{bottom:100%;border-color:transparent transparent #015288 transparent}

.nav .tooltip{right:10px}.nav .tooltip.bottom::before{left:86%}.nav .download{position:absolute;right:15px;top:8px;bottom:4px;left:85%}

.goal-container{height:inherit;display:flex;flex-direction:column;flex:1 0}.goal-container .goal{padding-bottom:10px}.goal-container .goal.active{z-index:20}.goal-container .goal.active img{box-shadow:5px 10px 8px #888888}.goal-container .goal div{position:relative}.goal-container .goal .tag{width:100px;position:absolute;bottom:16px;right:-10px}

.ReactModal__Body--open{overflow:hidden}.modal-content{position:absolute;border:1px solid #ccc;background:#fff;overflow:auto;outline:none;padding:15px;top:50%;transform:translateY(-50%);margin:10px;border-radius:8px;z-index:10;max-height:0;transition:max-height 1s ease-in-out;box-sizing:border-box;width:calc(100% - 20px);overflow:visible}.modal-content.slide-in{max-height:500px;transition:max-height 1s ease-in-out}.modal-overlay{position:fixed;top:0px;left:0px;right:0px;bottom:0px;background-color:rgba(0,0,0,0.3);z-index:21}

.popup-content{text-align:center}.popup-content p{margin:0 0 15px 0}.popup-content .title{font-size:14px}.popup-content .points{color:#ED5E1E;font-weight:bold}.popup-content .content{color:#969696;font-size:12px}.modal-content .tooltip{margin-top:8px}


.homework .tooltip{right:0 !important}.homework .clickable{right:0;bottom:0;height:25%;position:absolute;width:50%}


.next{bottom:5%;right:10%;position:fixed;z-index:21}.next span{color:#ffffff;font-weight:600}

.tiles-container{display:flex;flex-direction:column;height:100vh;position:relative}.tiles-container .tooltip{width:calc(50vw)}.tiles-container .tooltip.bottom:before{left:25%}.tiles-container .profile{height:220px}.tiles-container .profile img{height:220px}.tiles-container .content-box{padding:0 13px;display:flex;flex-direction:column;flex:1 0;margin-top:-35px;overflow:hidden}.tiles-container .content-box .row{display:flex;margin-bottom:6px;justify-content:space-between}.tiles-container .content-box .row .card{width:32%;height:130px;background-color:white;border-radius:3px;box-shadow:0px 0px 8px rgba(255,255,255,0.75)}.tiles-container .content-box .row .card.active{z-index:20}.tiles-container .content-box .row .card .d-full{width:100%;height:100%}.tiles-container .content-box Button{align-self:flex-end}

