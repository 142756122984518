.home-container {
    display: flex;
    flex-direction: column;
    height: 100vh;

    .svg {
        flex: 1 0 0;
    }

    .content-box {
        padding: 20px 20px 30px 20px;
        display: flex;
        flex-direction: column;
        flex: 1 0 0;

        .content {
            flex: 1;

            .title {
                font-size: 16px;
                margin-top: 0;
                margin-bottom: 8px;
            }

            .app-name {
                margin-top: 0;
                margin-bottom: 8px;
                font-size: 24px;
                color: #FF9A5A;
                font-weight: bold;
            }

            .text {
                margin-top: 0;
                font-size: 12px;
                color: #969696;
                line-height: 20px;
            }
        }

        Button {
            align-self: flex-end;
        }
    }
}