.homework {
    .tooltip {
        right: 0 !important;
    }

    .clickable {
        right: 0;
        bottom: 0;
        height: 25%;
        position: absolute;
        width: 50%;
    }
}