@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;600&display=swap');

body {
    font-family: 'Poppins', sans-serif;
}

.page-1 {
    top:0;
    left:0; 
    position: fixed;
    width:100%;
}

.page-1.slide-out {
    animation: slide-out 0.5s forwards;
}

@keyframes slide-out {
    from {
        left: 0;
    }
    
    to {
        left: -100%;
    }
}

.page-2 {
    top:0;
    left:100%; 
    position:fixed;
    width:100%;
}

.page-2.slide-in {
    animation: slide-in 0.5s forwards;
}

@keyframes slide-in {
    from {
        left: 100%;
    }

    to {
        left: 0;
    }
}

.page-1 {
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    z-index: 1;
    overflow: scroll;
    position: relative;

    .content-box {
        flex: 1;
        padding: 15px;
        display: flex;
        flex-direction: column;
    }
}


.page-2 {
    height: 100vh;
    display: flex;
    flex-direction: column;
    z-index: 1;

    .content-box {
        flex: 1;
        padding: 15px;
        display: flex;
        flex-direction: column;
        overflow: scroll;
    }
}


img {
    width: 100%;
}

.svg {
    width: 100%;
}

.active {
    z-index: 20;
}

.p-relative {
    position: relative;
}

.d-none {
    display: none;
}