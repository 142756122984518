.tiles-container {
    display: flex;
    flex-direction: column;
    height: 100vh;
    position: relative;

    .tooltip {
        width: calc(50vw);

        &.bottom:before {
            left: 25%;
        }
    }

    .profile {
        height: 220px;

        img {
            height: 220px;
        }
    }

    .content-box {
        padding: 0 13px;
        display: flex;
        flex-direction: column;
        flex: 1 0 0;
        margin-top: -35px;
        overflow: hidden;

        .row {
            display: flex;
            margin-bottom: 6px;
            justify-content: space-between;

            .card {
                width: 32%;
                height: 130px;
                background-color: white;
                border-radius: 3px;
                box-shadow: 0px 0px 8px rgba(255, 255, 255, 0.75);

                &.active {
                    z-index: 20;
                }

                .d-full {
                    width: 100%;
                    height: 100%;
                }
            }
        }

        Button {
            align-self: flex-end;
        }
    }
}