$pastel-orange: #FF6200;

// messages classes
.msg-info {
    color: #3C71E1;
}

.msg-success {
    color: #00A84F;
}

.msg-error {
    color: #eb4a57;
}

.msg-warning {
    color: #ED7100;
}
