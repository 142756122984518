.popup-content {
    text-align: center;

    p {
        margin: 0 0 15px 0;
    }

    .title {
        font-size: 14px;
    }

    .points {
        color: #ED5E1E;
        font-weight: bold;
    }

    .content {
        color: #969696;
        font-size: 12px;
    }
}

.modal-content .tooltip {
    margin-top: 8px;
}